<template>
  <div>
    <core-navigation-drawer
      :drawer-mini="drawerMini"
      :error="currentError"
      @alertVisibilityChanged="onAlertVisibilityChanged"
      @toggleMiniDrawer="toggleMiniDrawer"
    >
      <template v-slot:content>
        <!-- Parent record navigator -->
        <parent-record-navigator
          :breadcrumbs="breadcrumbs"
          :route-to-parent-folder="buildRouteToParentFolder()"
          :can-navigate-to-module-item="canNavigateToModuleItem"
          :create-module-item-content-route="createModuleItemContentRoute"
        >
        </parent-record-navigator>

        <v-divider></v-divider>

        <!-- Search Filter By Meeting Name -->
        <base-nav-list>
          <v-list-item dense>
            <search-text-field
              :loading="isLoading"
              v-model="searchDrawerItemName"
            ></search-text-field>
          </v-list-item>
        </base-nav-list>

        <!-- List child-records of selected parent -->
        <record-list
          :records="items"
          :create-record-target-route="createModuleItemContentRoute"
          :can-navigate-to-record="canNavigateToModuleItem"
          :create-child-record-target-route="
            createModuleChildRecordContentRouteLocation
          "
          :moved-record="movedRecord"
          :moved-records="movedRecords"
          @onRecordMoved="onRecordMoved"
          @onFileDropped="onFileDropped"
          @performContextMenuAction="performContextMenuAction"
        >
        </record-list>
      </template>
    </core-navigation-drawer>

    <move-record-dialog
      :visible="visibleMoveRecordDialog"
      :source-record="sourceRecord"
      :destination-record="destinationRecord"
      :is-move-by-position="isMoveByPosition"
      :insert-position="insertTreePosition"
      :move-event="onMoveRecordAction"
      @close="closeMoveRecordDialog"
    >
    </move-record-dialog>

    <copy-record-dialog
      :visible="visibleCopyRecordDialog"
      :source-record="sourceRecord"
      :destination-record="destinationRecord"
      :copy-event="onCopyRecordAction"
      @close="visibleCopyRecordDialog = false"
    >
    </copy-record-dialog>

    <!-- Delete current record Dialog -->
    <delete-record-dialog
      :record="selectedRecord"
      :visible="visibleDialogDelete"
      :perform-action="onRecordDelete"
      @close="visibleDialogDelete = false"
    ></delete-record-dialog>

    <agenda-item-refer-dialog
      :visible="visibleReferDialog"
      :source-record="sourceRecord"
      :destination-record="destinationRecord"
      :agenda-item-templates="agendaItemTemplates"
      :refer-event="onItemRefer"
      @close="visibleReferDialog = false"
    >
    </agenda-item-refer-dialog>

    <agenda-item-defer-dialog
      :visible="visibleDeferDialog"
      :source-record="sourceRecord"
      :destination-record="destinationRecord"
      :agenda-item-templates="agendaItemTemplates"
      :defer-event="onItemDefer"
      @close="visibleDeferDialog = false"
    >
    </agenda-item-defer-dialog>

    <batch-move-records-dialog
      :visible="visibleBatchMoveRecordsDialog"
      :record-list="batchRecordList"
      :destination-record="destinationRecord"
      :move-operation="batchMoveOperation"
      :move-event="onBatchMoveAction"
      :move-event-completed="clearSavedRecord"
      @close="closeBatchMoveRecordsDialog"
    ></batch-move-records-dialog>

    <batch-move-items-dialog
      :visible="visibleBatchMoveItemsDialog"
      :record-list="batchRecordList"
      :destination-record="destinationRecord"
      :agenda-item-templates="agendaItemTemplates"
      :move-operation="batchMoveOperation"
      :move-event="onBatchMoveItemsAction"
      :move-event-completed="clearSavedRecord"
      @close="closeBatchMoveItemsDialog"
    ></batch-move-items-dialog>

    <upload-local-file-dialog
      :visible="visibleUploadLocalFileDialog"
      :local-file="draggedFile"
      :destination-record="destinationRecord"
      :upload-event="onUploadLocalFile"
      @close="visibleUploadLocalFileDialog = false"
    >
    </upload-local-file-dialog>

    <submit-recall-agenda-items-dialog
      :record="selectedRecord"
      :visible="visibleSubmitRecallAgendaItemsDialog"
      :is-recall="isRecallOperation"
      :perform-action="onItemsSubmitRecall"
      @close="closeSubmitRecallAgendaItemsDialog"
    ></submit-recall-agenda-items-dialog>

    <!-- display snackbar on demand -->
    <snackbar
      v-model="snackbarVisible"
      @close="closeSnackbar"
      :color="snackbarColor"
      :top="snackbarTop"
      :timeout="snackbarTimeout"
      :multi-line="snackbarMultiLine"
      >{{ snackbarText }}</snackbar
    >
  </div>
</template>

<script>
import { agendaDrawerMixin } from "@/mixins/agenda/drawer/agendaDrawerMixin";

export default {
  name: "AgendaDrawer",
  mixins: [agendaDrawerMixin],
  components: {
    ParentRecordNavigator: () =>
      import("@/components/record/ParentRecordNavigator"),
    RecordList: () => import("@/components/record/RecordList")
  }
};
</script>
