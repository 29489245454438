// services
import {
  getStoredSelectedModuleItemId,
  getStoredSelectedModuleExpandedId
} from "@/services/agenda/agendaModuleService";

// design
import { iconEvent, iconFilterMenu } from "@/design/icon/iconConst";

// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapMutations,
  mapGetters,
  mapActions
} = createNamespacedHelpers("agenda");

// store
import {
  SET_DRAWER_MINI,
  SET_LOCAL_UPLOADED_FILE,
  SET_MOVED_RECORD,
  SET_MOVED_RECORDS,
  SET_RECORDS,
  UPDATE_RECORD_LIST_BY_ID
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// mixins
import { moduleItemsDrawerMixin } from "@/mixins/shared/base/drawer/moduleItemsDrawerMixin";
import { browsableFolderMixin } from "@/mixins/shared/browsable/browsableFolderMixin";
import { badgeableRecordMixin } from "@/mixins/shared/record/badgeableRecordMixin";

/**
 * agendaDrawerMixin
 */
export const agendaDrawerMixin = {
  mixins: [moduleItemsDrawerMixin, browsableFolderMixin, badgeableRecordMixin],
  data() {
    return {
      iconEvent: iconEvent,
      iconFilterMenu: iconFilterMenu
    };
  },
  computed: {
    ...mapState({
      records: "records",
      breadcrumbs: "breadcrumbs",
      record: "record",
      movedRecord: "movedRecord",
      movedRecords: "batchMovedRecords",
      fetchRecordListById: "fetchRecordListById"
    }),

    /**
     * Overwrite moduleItemsDrawerMixin Getters
     */
    ...mapGetters(["drawerMini", "selectedModuleItemId"]),

    /**
     * Overwrite (record) items
     * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    items() {
      if (this.searchDrawerItemName) {
        return this.records?.filter(el => {
          return el.name
            .toLowerCase()
            .startsWith(this.searchDrawerItemName.toLowerCase());
        });
      }
      return this.records ?? [];
    },

    /**
     * suggested Select (Record) Module Item id
     * @return {Number|number}
     */
    defaultSelectModuleItemId() {
      return this.lastBreadcrumbId >= 0
        ? this.lastBreadcrumbId
        : this.itemCount > 0
        ? this.items[0].id
        : 0;
    }
  },
  methods: {
    ...mapActions({
      setAgendaMeetings: "setAgendaMeetings",
      setChildRecords: "setChildRecords",
      setBreadcrumbs: "setBreadcrumbs",
      setParentBreadcrumbs: "setParentBreadcrumbs",
      moveRecord: "moveRecord",
      moveMeetingContents: "moveMeetingContents",
      moveBackupMaterial: "moveBackupMaterial",
      copyRecord: "copyRecord",
      deleteRecord: "deleteRecord",
      referAgendaItem: "referAgendaItem",
      deferAgendaItem: "deferAgendaItem",
      uploadFile: "uploadFile",
      checkInFile: "checkInFile",
      submitMeetingAgendaItems: "submitMeetingAgendaItems",
      submitSectionAgendaItems: "submitSectionAgendaItems",
      recallMeetingAgendaItems: "recallMeetingAgendaItems",
      recallSectionAgendaItems: "recallSectionAgendaItems"
    }),

    /**
     * Overwrite moduleItemsDrawerMixin Mutations
     */
    ...mapMutations({
      setDrawerMini: SET_DRAWER_MINI,
      setMovedRecord: SET_MOVED_RECORD,
      setMovedRecords: SET_MOVED_RECORDS,
      setLocalUploadedFile: SET_LOCAL_UPLOADED_FILE,
      setRecords: SET_RECORDS,
      updateRecordListById: UPDATE_RECORD_LIST_BY_ID
    }),

    /**
     * Get Stored Selected Module Item id (Record id)
     * Overwrites moduleItemsDrawerMixin.getStoredSelectedModuleItemId
     * @returns {Number|number}
     */
    getStoredSelectedModuleItemId: () => getStoredSelectedModuleItemId(),

    /**
     * get Stored Selected Module Expanded id
     * @return {Number|number}
     */
    getStoredSelectedModuleExpandedId: () =>
      getStoredSelectedModuleExpandedId(),

    /**
     * list (record) Items
     * @param {Number|number} id
     * @return {Promise<void>}
     */
    async listItems(id) {
      try {
        this.clearError();

        this.isLoading = true;

        if (id > 0) {
          const payload = {
            id: id,
            recordIndex: 1,
            count: this.expandedRecordChildren ?? 50
          };
          await this.setChildRecords(payload);
        } else {
          await this.listAgendaMeetings();
        }
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Load, set and list Agenda Meetings
     * @returns {void}
     */
    async listAgendaMeetings() {
      try {
        this.clearError();

        this.isLoading = true;

        const payload = {
          startPage: 1,
          countPerPage: 1000
        };

        await this.setAgendaMeetings(payload);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
